import React, { Component } from "react"
// import { Index } from "elasticlunr"
// import elasticlunr from 'elasticlunr'
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch} from '@fortawesome/free-solid-svg-icons'

export default class Search extends Component {
  state = {
    query: ``,
    results: []
 }

 // componentDidMount() {
 //   elasticlunr.clearStopWords();
 //
 // }
//TODO  on input
  render() {
    return (
      <div style={{maxWidth:"170px"}} className="searchcontainer">
      <div className="input-group input-group-sm">

<input value={this.state.query} onChange={this.search} style={{maxWidth:"150px"}} type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
<div className="input-group-prepend">
<span style={{backgroundColor:"black"}} className="input-group-text" id="inputGroup-sizing-sm">
<FontAwesomeIcon  style={{width:"1em", height:"1em", color:"white"}} size="xs" onClick={()=> {this.setState({search:true})}} icon={faSearch}/>
</span>
</div>
</div>
        {/* <form className="form-inline">
      <i className="fas fa-search" aria-hidden="true"></i>
     <input value={this.state.query} onChange={this.search}   className="form-control form-control-lg mx-md-3 w-100 " type="text" placeholder="Search"
    aria-label="Search"/>
     </form> */}


        <ul className={`searchresults ${this.state.results[0]? 'searchtransition' : null}`}>
          {this.state.results.map(page => (
            <li className="my-2" key={page.title}>
              {/*<img className="mr-1" src={page.imagepath} style={{width:'50px', height:'50px'}}/>*/}
              <Link to={page.url}>{page.title}</Link>

            </li>
          ))}
        </ul>
      </div>
    )
  }

  // getOrCreateIndex = () => {
  //   return this.index
  //     ? this.index
  //     : // Create an elastic lunr index and hydrate with graphql query results
  //     Index.load(this.props.searchIndex)
  // }

  search = evt => {
    const query = evt.target.value

    // this.index = this.getOrCreateIndex()
    this.setState({
      results: this.props.searcharray.filter((item)=> {
        if(!query){
          return false
        }
        return item.title.toLowerCase().includes(query.toLowerCase())
      }),
      query: query
    })


  }
}
