

import React from "react"
import {Container, Navbar, Nav, Row, Col} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faFacebook, faInstagram, faTwitter,faLinkedinIn, faPinterest} from '@fortawesome/free-brands-svg-icons'



import {Link} from 'gatsby'
import Search from './search'


class Header extends React.Component {




  render() {
    var fontsize = {
     fontSize:"25px",
     width:"25px",
     height:"25px"
 };
    return (
      <header>
      <Container style={{maxWidth:'1600px'}}  >
        <Navbar  expand="xl">


                <Navbar.Brand className='navbrandcontainer' ><Link to="/"><img style={{maxWidth:'350px'}} className="w-100" src="/logoswsto.png" alt="logo"/></Link></Navbar.Brand>



              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse className="flex-column" id="basic-navbar-nav">

                <Nav className="ml-auto">

                <Row>

                <Col className="d-md-flex d-none  justify-content-md-end socialcontainer" xs={12}  md={12}>


                <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/thebeautysyrup/"><FontAwesomeIcon style={fontsize}  className="footericons mx-2" icon={faFacebook}/></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thebeautysyrup/"><FontAwesomeIcon style={fontsize}    className="footericons mx-2" icon={faInstagram}/></a>
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/KartaliMarilena"><FontAwesomeIcon style={fontsize}    className="footericons mx-2" icon={faTwitter}/></a>
                      <a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.co.uk/marilenakartali/"><FontAwesomeIcon style={fontsize}   className="footericons mx-2" icon={faPinterest}/></a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/marilena-kartali-kaouni-59b93b65"><FontAwesomeIcon style={fontsize}  className="footericons mx-2" icon={faLinkedinIn}/></a>






                 </Col>

                 <Col className="d-xl-flex  justify-content-xl-end" md={12}>
                 {this.props.menu.map((item,i)=> {
                   return (
                      <Link className={`nav-link ${this.props.path === item.url.path ? 'active' : null}`}  to={item.url.path} key={i}>{item.label}</Link>
                   )

                 })}

                 <div className="my-auto">
                   {/* <div class="input-group input-group-sm  position-relative">
  <div class="input-group-prepend">
    <span class="input-group-text" id="inputGroup-sizing-sm">
      <FontAwesomeIcon  style={{width:"1em", height:"1em"}} size="xs" onClick={()=> {this.setState({search:true})}} icon={faSearch}/>
    </span>
  </div>
  <input style={{maxWidth:"150px"}} type="text" class="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"/>
</div> */}
<Search searcharray={this.props.searcharray}/>





                 </div>

                 </Col>






                </Row>





                </Nav>

              </Navbar.Collapse>





  </Navbar>

      </Container>

      </header>

    )
  }

}

export default Header
