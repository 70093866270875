/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/main.scss'
// import { useStaticQuery, graphql } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {faFacebook, faInstagram, faTwitter,faLinkedinIn, faPinterest} from '@fortawesome/free-brands-svg-icons'
import {Container, Row, Col} from 'react-bootstrap'
import Header from "./header"
import {Link} from 'gatsby'




// const Layout = ({ children }) => {
//   const data = useStaticQuery(graphql`
//     query SiteTitleQuery {
//       site {
//         siteMetadata {
//           title
//         }
//       }
//     }
//   `)

class Layout extends React.Component {
  render() {
    var fontsize = {
     fontSize:"25px",
     width:"25px",
     height:"25px"
 };
    return (
      <div>
        <Header searcharray={this.props.searcharray} path={this.props.path} menu={this.props.menu} siteTitle={`FAshion`} />
        <Container className="wrapper">
          <main className="">{this.props.children}</main>

        </Container>
        <footer className={`${this.props.fixed?"fixed" :""}`}>
          <div className="footrow">
          <Container>
            <Row className="p-3">
             <Col className="text-white text-center d-flex align-items-center justify-content-center" md={6} xs={12}>
               <Link to="/"><span className="border-right pr-2">The Beauty Syrup</span></Link>

               <Link to="/contact"><span className=" pl-2">Contact</span></Link>

             </Col>
             <Col  className="text-white text-center" md={6} xs={12}>
               <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/thebeautysyrup/"><FontAwesomeIcon style={fontsize} className="footericons mx-2" icon={faFacebook}/></a>
                 <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/thebeautysyrup/"><FontAwesomeIcon style={fontsize} className="footericons mx-2" icon={faInstagram}/></a>
                   <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/KartaliMarilena"><FontAwesomeIcon style={fontsize} className="footericons mx-2" icon={faTwitter}/></a>
                     <a target="_blank" rel="noopener noreferrer" href="https://www.pinterest.co.uk/marilenakartali/"><FontAwesomeIcon style={fontsize} className="footericons mx-2" icon={faPinterest}/></a>
                       <a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/marilena-kartali-kaouni-59b93b65"><FontAwesomeIcon style={fontsize} className="footericons mx-2" icon={faLinkedinIn}/></a>








             </Col>
            </Row>
          </Container>
          </div>
          <div className="copyright text-white text-center">
           <Container>
              <Row>
               <Col xs={12} md={12}>
               <div style={{fontSize:"0.9rem"}}>
               <p>Copyright © {new Date().getFullYear()}. All Rights Reserved.</p>
            </div>
               </Col>
              </Row>
           </Container>
          </div>
        </footer>
      </div>
    )

  }
}






export default Layout
